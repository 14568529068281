// Unpublished Work © 2024 Deere & Company

//stylelint-disable selector-class-pattern

.widget-grid-tablet .daily-work-summary-data.HARVEST {
    grid-template-columns: 1fr 1fr 1fr;

    .daily-work-summary-box:nth-child(5) {
        grid-column: span 1;
    }
}

.daily-work-summary-widget {
    .MuiTabPanel-root {
        padding: 16px 0 0;
    }

    .tabs-container {
        padding: 0 16px;
    }

    .date-picker {
        margin: 0 0 8px 16px;
    }

    .daily-work-summary-header {
        padding: 0 16px;
        display: grid;
        grid-template-columns: 1fr;

        &.SEEDING,
        &.HARVEST {
            margin-bottom: 16px;
        }
    }

    .daily-work-summary-content {
        min-height: 160px;
    }

    .daily-work-summary-content.daily-work-summary-empty-content {
        min-height: 320px;
    }

    .skeleton-crop-name {
        margin-left: 16px;
        margin-top: 16px;
    }

    .no-daily-work-summary-title {
        background-color: #f7f7f7;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 24px;
        text-align: center;
        margin-bottom: 16px;
    }

    .daily-work-summary-list {
        .MuiListItemButton-root:hover {
            background-color: transparent;
            cursor: default;
        }

        .MuiListItemButton-root {
            cursor: default;
            padding: 0;
        }

        .MuiListItem-root {
            border: none;
        }

        .MuiTypography-dark {
            font-weight: 600;
            margin-left: 16px;
        }
    }

    .daily-work-summary-data {
        margin: 0 16px;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 16px;
        padding-bottom: 16px;
        white-space: normal;

        &.LOADING {
            padding-bottom: 0;
        }

        &.SEEDING,
        &.HARVEST {
            margin: 8px 16px 0;
        }
    }

    .daily-work-summary-box {
        border: 1px solid #ddd;
        border-radius: 4px;
        color: #767676;
        padding: 8px 16px;
        display: inline-block;

        span {
            display: block;
        }

        h2 {
            color: #333;
            display: inline-block;
            font-weight: 700; //stylelint-disable-line font-weight-notation
            padding-right: 4px;
        }

        .daily-work-summary-unit {
            color: #333;
            display: inline-block;
        }
    }

    .daily-work-summary-box:nth-child(5) {
        grid-column: span 2;
    }
}
