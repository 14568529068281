// Unpublished Work © 2024 Deere & Company

.alerts-widget {
    padding: 0 16px;

    div.alerts-accordion {
        margin-top: 0;
        margin-bottom: 0;

        .MuiStack-root { //stylelint-disable-line selector-class-pattern
            width: 100%;
        }

        .alerts-metric-details {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 16px;
            margin-bottom: 16px;
            text-align: center;
        }

        .alerts-monitor-in-map-box .MuiListItem-root { //stylelint-disable-line selector-class-pattern
            border: 1px solid #ddd;
            border-radius: 4px;

            .MuiListItemButton-root { //stylelint-disable-line selector-class-pattern, selector-max-compound-selectors
                padding: 16px;
            }
        }

        .alerts-icon-badge {
            background-color: #c21020;
            border-radius: 50%;
            min-width: 12px;
            width: 12px;
            height: 12px;
            margin-right: 7px;
        }

        .alert-title-ellipsis {
            display: grid;
            width: 98%;
        }

        .alert-row-content {
            background-color: #fdf6f6;
            padding: 12px 16px;
            border-radius: 4px;
            border: 1px solid #dd737e;
            gap: 8px;
            margin-bottom: 16px;
        }
    }

    .alerts-accordion:not(:last-child) {
        border-bottom: 0;
    }

    .alerts-empty-state {
        background-color: #f7f7f7;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 24px;
        text-align: center;
        margin-bottom: 16px;
    }
}
