// Unpublished Work © 2024 Deere & Company.

.equipment-widget {
    &.mobile {
        height: 272px;
    }

    &.mobile:has(.equipment-empty-state-wrapper) {
        height: 318px;
    }

    .map-icon-btn {
        margin-right: 8px;
    }

    .equipment-empty-state-wrapper {
        padding: 16px;

        .equipment-empty-state {
            background-color: #f7f7f7;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 24px;
            text-align: center;
        }
    }

    .loading-equipment {
        border-bottom: 1px solid #ddd;
        display: flex;
        padding: 18px;

        .loading-details {
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding-left: 8px;
        }
    }

    .equipment-details-row {
        color: #333;
        display: flex;
        gap: 4px;
        height: 20px;

        .fuel,
        .def {
            align-items: center;
            display: flex;
            gap: 2px;
        }
    }

    .active-equipment-list.mobile-height {
        min-height: 272px !important; // stylelint-disable-line declaration-no-important
    }
}

.active-equipment-row-map-icon {
    padding-right: 8px;
    display: flex;
}

.active-equipment-row-map-icon:hover {
    cursor: pointer;
}

.active-equipment-status {
    display: flex;
    flex-direction: row;
    align-items: center;
}
