// Unpublished Work © 2024 Deere & Company

.welcome-banner {
    min-height: 82px;
    border-style: solid;
    border-width: 0 0 1px;
    border-bottom-color: #ddd;
    background-color: #fff;
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.welcome-banner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

.date-and-address {
    display: flex;
    flex-direction: column;
}

.address-skeleton {
    margin-top: 4px;
}

.address-ellipsis {
    display: grid;
    width: 100%;
    max-width: 500;
}

.weather-container {
    display: flex;
    flex-direction: row;
    gap: 36px;
}

.weather-block {
    display: flex;
    flex-direction: row;
    padding: 8px 0;
    gap: 4px;
    align-items: center;
}

.weather-icon {
    margin-right: 4px;
    display: flex;
    align-items: center;
}

.weather-skeleton {
    margin-right: 24px;
}

.weather-data {
    display: flex;
    flex-direction: column;
}

.landing-prompt-ellipsis {
    display: grid;
    width: 100%;
}

.welcome-banner-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (width <= 1209px) {
    .welcome-banner {
        min-height: 126px;
        padding-bottom: 8px;
        flex-direction: column;
        align-items: flex-start;

        .weather-skeleton {
            margin-top: 2.5px;
            margin-bottom: 2.5px;
        }

        .welcome-banner-toggle {
            margin-top: 11px;

            .MuiSwitch-root { //stylelint-disable-line selector-class-pattern
                margin-inline-start: 0;
            }
        }

        .welcome-banner-container {
            width: 100%;
            gap: 0;
        }

        .weather-block:nth-child(odd) {
            .weather-value {
                width: 195px;
            }
        }

        .weather-block:nth-child(even) {
            .weather-value {
                width: 100px;
            }
        }

        .welcome-banner-address-date-content {
            min-width: 384px;
            max-width: 648px;
            width: 100%;
        }

        .weather-container {
            display: grid;
            grid-template-columns: 223px 155px;
            gap: 4px;
        }
    }
}

@media (width <= 834px) {
    .welcome-banner {
        min-height: 126px;
        padding-bottom: 8px;

        .weather-skeleton {
            margin-top: 2.5px;
            margin-bottom: 2.5px;
        }

        .welcome-banner-container {
            flex-direction: column;
            align-items: flex-start;
        }

        .welcome-banner-address-date-content {
            padding-bottom: 16px;
        }

        .welcome-banner-toggle {
            margin-top: 8px;

            .MuiSwitch-root { //stylelint-disable-line selector-class-pattern
                margin-inline-start: 0;
            }
        }

        .weather-container {
            display: grid;
            grid-template-columns: 223px 155px;
            gap: 0;
        }
    }
}
