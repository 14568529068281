// Unpublished Work © 2024 Deere & Company.

.discover-widget {
    // stylelint-disable-next-line selector-class-pattern
    .MuiCardContent-root {
        padding: 0 16px 16px !important;  // stylelint-disable-line declaration-no-important
    }

    .discover-empty-state {
        background-color: #f7f7f7;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 24px;
        margin-top: 16px;
        text-align: center;
    }

    .discover-link {
        text-decoration: none;
        color: #333;
        cursor: pointer;
    }

    .discover-content {
        border: 1px solid;
        border-radius: 4px;
        border-color: #ddd;
        border-style: solid;
        padding: 16px;

        &.skeleton {
            padding: 24px 16px;
        }

        .discover-panel-title {
            align-content: end;
            height: 48px;
            line-height: 24px;
        }

        .discover-panel-text {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            height: 40px;
        }

        .discover-link-wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: end;
            padding-top: 16px;
        }

        &.roi-calculator {
            .discover-panel-subtext {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                height: 40px;
            }
        }

        .discover-img {
            height: 100%;
            width: 100%;
            padding: 16px 0;
        }

        .bottom-skeleton-container {
            display: flex;
            justify-content: flex-end;
        }
    }

    .footer-link-button {
        height: fit-content;
    }

    .footer-link-button-text {
        white-space: normal;
    }
}
