// Unpublished Work © 2024 Deere & Company.

.rainfall-widget {
    .MuiCardContent-root { //stylelint-disable-line selector-class-pattern
        padding: 0 16px;
    }

    .rainfall-multiselect {
        padding-bottom: 16px;
    }

    .rainfall-skeleton {
        .rainfall-skeleton-header {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px;
            padding-bottom: 16px;

            .rainfall-skeleton-box {
                border: 1px solid #ddd;
                border-radius: 4px;
                color: #767676;
                padding: 8px 16px;
                display: inline-block;
            }
        }

        .rainfall-skeleton-list {
            border-top: 1px solid #ddd;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            border-radius: 4px;
            color: #767676;

            .rainfall-skeleton-row {
                display: grid;
                grid-template-columns: .1fr 1fr 1fr;
                padding: 10px 16px;
                border-bottom: 1px solid #ddd;

                .rainfall-skeleton-content { //stylelint-disable-line selector-max-compound-selectors
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    padding-left: 8px;
                }

                .rainfall-skeleton-unit { //stylelint-disable-line selector-max-compound-selectors
                    justify-self: flex-end;
                }
            }
        }
    }

    .rainfall-content {
        .rainfall-content-header {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 16px;

            .rainfall-box {
                border: 1px solid #ddd;
                border-radius: 4px;
                color: #767676;
                padding: 8px 16px;
                display: inline-block;

                .value { //stylelint-disable-line selector-max-compound-selectors
                    color: #333;
                    display: inline-block;
                    font-size: 24px;
                    font-weight: 700; //stylelint-disable-line font-weight-notation
                }

                .unit { //stylelint-disable-line selector-max-compound-selectors
                    font-weight: normal;
                    font-size: 14px;
                    padding-left: 4px;
                }
            }
        }

        .rainfall-fields {
            border: 1px solid #ddd;
            border-radius: 4px;
            margin-top: 16px;
            overflow: hidden;

            .MuiListItemSecondaryAction-root { //stylelint-disable-line selector-class-pattern
                padding-right: 16px;
                position: static;
                text-wrap: nowrap;
                transform: none;
            }

            .MuiTypography-dark { //stylelint-disable-line selector-class-pattern
                color: #333;
                font-weight: 400; //stylelint-disable-line font-weight-notation
            }

            .MuiButtonBase-root { //stylelint-disable-line selector-class-pattern
                background-color: transparent;
                cursor: default;
                height: 56px;
                padding-right: 16px;
            }

            #rainfall-last { //stylelint-disable-line selector-max-id
                border: none;
            }
        }
    }

    &.empty {
        .MuiCardContent-root { //stylelint-disable-line selector-class-pattern
            padding-bottom: 16px;
        }

        .rainfall-empty-state-wrapper {
            padding-bottom: 16px;

            .rainfall-empty-state {
                background-color: #f7f7f7;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding: 24px;
                text-align: center;
            }
        }
    }
}
