// Unpublished Work © 2024 Deere & Company

.daily-field-summary-widget {
    .MuiCardContent-root { //stylelint-disable-line selector-class-pattern
        padding: 0 16px 16px;
    }

    .daily-field-summary-data {
        border: 1px solid;
        border-radius: 4px;
        border-color: #ddd;
        border-style: solid;
        padding: 16px 16px 16px 0;
        margin: 16px 0 0;
    }

    .daily-field-summary-header {
        display: inline-flex;
        width: calc(100% - 16px);
        gap: 16px;
        padding-bottom: 16px;
        padding-left: 16px;

        .field-info-ellipsis {
            display: grid;
            width: 100%;
        }
    }

    .measurement-box-ellipsis {
        display: grid;
        width: 100%;
    }

    .field-map {
        align-items: center;
        border-radius: 4px;
        display: flex;
        height: 60px;
        justify-content: center;
        width: 60px;
    }

    .field-info {
        width: 65%;
    }

    .daily-field-summary-products {
        padding-left: 16px;
        padding-top: 16px;

        .link-wrapper {
            display: flex;
            justify-content: flex-end;
        }

        .crops-list {
            .crop-item {
                .variety { //stylelint-disable-line selector-max-compound-selectors
                    color: #333;
                    border-top: 1px solid #ddd;
                    padding: 8px 0 8px 16px;
                }
            }

            .MuiListItemButton-root:hover { //stylelint-disable-line selector-class-pattern
                background-color: transparent;
                cursor: default;
            }

            .MuiListItemButton-root { //stylelint-disable-line selector-class-pattern
                cursor: default;
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .MuiListItem-root { //stylelint-disable-line selector-class-pattern
                border: 1px solid #ddd;
                border-radius: 4px;
                margin-top: 16px;
            }

            [data-index='0'] {
                .MuiListItem-root { //stylelint-disable-line selector-class-pattern,selector-max-compound-selectors
                    margin-top: 0;
                }
            }

            .MuiButtonBase-root { //stylelint-disable-line selector-class-pattern
                padding: 0;
            }

            .MuiTypography-dark { //stylelint-disable-line selector-class-pattern
                color: #767676;
                font-weight: 700; //stylelint-disable-line font-weight-notation
                padding-left: 16px;
                padding-bottom: 10px;
                padding-top: 10px;
            }
        }

        .products-list {
            margin-bottom: 8px;

            .MuiListItemButton-root:hover { //stylelint-disable-line selector-class-pattern
                background-color: transparent;
                cursor: default;
            }

            .MuiListItemButton-root { //stylelint-disable-line selector-class-pattern
                cursor: default;
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .MuiListItem-root { //stylelint-disable-line selector-class-pattern
                border-bottom: none;
                border-top: 1px solid #ddd;
            }

            [data-index='0'] {
                .MuiListItem-root { //stylelint-disable-line selector-class-pattern,selector-max-compound-selectors
                    border-top: none;
                }
            }

            [data-testid='virtuoso-item-list'] {
                border: 1px solid #ddd;
                border-radius: 4px;
            }

            .MuiTypography-dark { //stylelint-disable-line selector-class-pattern
                color: #333;
                font-weight: 400; //stylelint-disable-line font-weight-notation
            }
        }

        .empty-products {
            border: 1px solid #ddd;
            border-radius: 4px;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 16px;
            margin-bottom: 196px;
        }

        .empty-products-no-margin {
            border: 1px solid #ddd;
            border-radius: 4px;

            .empty-products-title {
                color: #767676;
                font-weight: 700; //stylelint-disable-line font-weight-notation
                padding-left: 16px;
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .empty-products-row {
                border-top: 1px solid #ddd;
                padding-left: 16px;
                padding-top: 8px;
                padding-bottom: 8px;
            }
        }
    }

    .daily-field-summary-equipment {
        padding-left: 16px;
        padding-top: 16px;

        .link-wrapper {
            display: flex;
            justify-content: flex-end;
        }

        .equipment-list {
            margin-bottom: 8px;

            &.empty {
                margin-bottom: 0;
            }

            .MuiListItemButton-root:hover { //stylelint-disable-line selector-class-pattern
                background-color: transparent;
                cursor: default;
            }

            .MuiListItemButton-root { //stylelint-disable-line selector-class-pattern
                cursor: default;
                padding-top: 8px;
                padding-bottom: 8px;
            }

            .MuiListItem-root { //stylelint-disable-line selector-class-pattern
                border: 1px solid #ddd;
                border-radius: 4px;
                margin-top: 16px;
            }

            [data-index='0'] {
                .MuiListItem-root { //stylelint-disable-line selector-class-pattern,selector-max-compound-selectors
                    margin-top: 0;
                }
            }

            .MuiTypography-dark { //stylelint-disable-line selector-class-pattern
                color: #333;
                font-weight: 600; //stylelint-disable-line font-weight-notation
            }
        }
    }

    .daily-field-summary-metrics {
        padding-left: 16px;
        padding-top: 8px;

        .link-wrapper {
            display: flex;
            justify-content: flex-end;
        }

        .daily-field-summary-data-row {
            display: flex;
            justify-content: space-between;
            gap: 16px;
            padding: 8px 0;

            &.extra-space {
                padding-bottom: 90px;
            }
        }

        .daily-field-summary-box {
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 8px 16px;
            display: inline-block;
            width: 100%;

            span {
                display: block;
            }

            p {
                color: #767676;
            }

            h2 {
                color: #333;
                display: inline-block;
                font-weight: 700; //stylelint-disable-line font-weight-notation
            }

            .daily-field-summary-unit {
                color: #333;
                display: inline-block;
                padding-left: 4px;
            }
        }
    }

    .skeleton-wrapper {
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 16px 16px 16px 0;
        margin: 16px 0 0;
        display: inline-block;
        width: 100%;

        .skeleton-header {
            display: flex;
            padding-left: 16px;
            padding-bottom: 16px;

            .skeleton-header-info {

            }
        }
    }

    .no-daily-field-summary-title {
        background-color: #f7f7f7;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 24px;
        text-align: center;
        margin: 16px 0 16px 16px;
    }
}
