// Unpublished Work © 2023-2024 Deere & Company.

html,
body,
main {
    height: 100%;
    overflow: hidden;
    position: relative;
}

.basic-widget {
    padding-bottom: 24px;
}

.card-wrapper {
    padding-bottom: 24px;
}

.card-header {
    .MuiCardHeader-action { //stylelint-disable-line selector-class-pattern
        align-self: center;
        margin-right: 0;
    }

    &:hover {
        cursor: pointer;
        background: #f7f7f7;
    }

    &.disable-collapse {
        pointer-events: none;

        .MuiIconButton-root { //stylelint-disable-line selector-class-pattern
            box-shadow: none;
            opacity: .44;
        }
    }
}

.widget-grid-wrapper {
    display: flex;
    justify-content: center;
}

.widget-grid {
    padding: 32px;
    max-width: 1464px;
}

.widget-grid-tablet {
    padding: 32px;
}

.widget-grid-mobile {
    padding: 32px;
}

@media (width <= 436px) {
    .widget-grid-mobile {
        padding: 16px;
    }
}
