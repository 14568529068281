// Unpublished Work © 2024 Deere & Company.

.setup-widget {
    &.expanded {
        padding-bottom: 0;
    }

    .setup-expanded {
        display: flex;
        gap: 16px;

        &.stack-view {
            flex-direction: column;
        }
    }

    .no-new-setup-tips {
        background-color: #f7f7f7;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 24px;
        text-align: center;
    }

    .setup-tip {
        border-radius: 4px;
        border-style: solid;
        border-color: #b3b3b3;
        border-width: 1px;
        display: flex;
        width: 100%;
    }

    .setup-tip-icon {
        background-color: #f3f7fb;
        max-width: 80px;
        min-width: 80px;
        min-height: 121px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .setup-tip-text {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        padding: 0 24px;

        .setup-tip-title {
            display: grid;
            width: 100%;
        }

        .setup-tip-subtext {
            display: -webkit-box; //stylelint-disable-line
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical; //stylelint-disable-line
            white-space: unset;
            overflow: hidden;
        }
    }
}
