// Unpublished Work © 2024 Deere & Company

.season-work-widget {
    .tabs-container {
        display: flex;
        padding: 0 16px;
    }

    .loading-season-work-progress {
        display: flex;
        margin-bottom: 16px;

        .loading-details {
            padding: 0 16px;
            width: 100%;

            &.top {
                width: 100%;
                border-top: 1px solid #ddd;
                padding-top: 16px;
            }
        }
    }

    .MuiTabPanel-root { //stylelint-disable-line selector-class-pattern
        padding: 16px 0 0;
    }

    .season-work-progress-header {
        padding: 0 16px;
    }

    .season-progress-empty-state {
        background-color: #f7f7f7;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 16px;
        padding: 24px;
        text-align: center;
    }

    .total-season-work-progress-total-acres {
        border: 1px solid;
        border-radius: 4px;
        padding: 12px 18px;
        border-color: #ddd;
        border-style: solid;
        border-left-width: 6px;
        margin-bottom: 8px;

        .total-area-font {
            font-size: 24px;
            font-weight: bold;
            display: inline-block;

            .unit {
                font-size: 15px;
                font-weight: normal;
                vertical-align: middle;
            }
        }
    }

    .season-work-progress-crops-list {
        .MuiListItem-root { //stylelint-disable-line selector-class-pattern
            border: none;
        }

        .MuiTypography-dark { //stylelint-disable-line selector-class-pattern
            font-weight: 600;
        }
    }

    .season-work-all-crops {
        .MuiTypography-h6 { //stylelint-disable-line selector-class-pattern
            font-size: 14px;
            line-height: 20px;
        }
    }

    .add-scroll-width {
        overflow: scroll;
        overflow-x: hidden;
        scrollbar-color: transparent transparent;
    }

    .unclickable-content {
        .MuiListItemButton-root { //stylelint-disable-line selector-class-pattern
            background: transparent;
            cursor: default;
            padding-right: 16px;
        }

        .MuiIconButton-root { //stylelint-disable-line selector-class-pattern
            box-shadow: none;
            opacity: .44;
            background: transparent;
            cursor: default;
        }
    }

    .season-work-data {
        padding-top: 4px;

        &.last {
            padding-bottom: 8px;
        }
    }

    .season-work-edit-targets {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .loading-details {
        &.all-crops {
            padding-bottom: 8px;
        }
    }
}
