// Unpublished Work © 2024 Deere & Company

.work-plans-widget {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .work-plans-chevron :first-child {
        height: 18px;
        width: 18px;
    }

    .plan-work-menu {
        align-self: center;

        button {
            border: unset;
            background: unset;
        }

        svg {
            height: 18px;
            width: 18px;
        }
    }

    .work-plans-single-counts-skeleton {
        display: flex;
        justify-content: flex-end;
    }

    .work-plans-empty-state {
        background-color: #f7f7f7;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 24px;
        text-align: center;
    }

    .work-plans-total-counts-block {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &.empty-state {
            flex-direction: row;
        }

        .work-plans-planned-counts {
            display: flex;
            width: 100%;
        }
    }

    .work-plans-total-counts {
        border: 1px;
        flex: 1;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        border-style: solid;
        padding: 8px 16px;
        border-color: #ddd;
        gap: 4px;
        min-height: 68px;
        justify-content: space-around;
        overflow: hidden;

        &.unplanned-counts {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .work-plans-planned-counts {
        flex-direction: row;
        gap: 16px;
    }

    .work-plans-table {
        border-radius: 4px;
        border-bottom: none;
    }

    .work-plans-row:hover {
        background-color: #f7f7f7;
        cursor: pointer;
    }

    .work-plans-sticky-color {
        height: 100%;
        width: 100%;
        border-style: none none none solid;
        border-left-width: 6px;
        display: flex;
        align-items: center;
        padding: 8px 0 8px 16px;
    }
}
