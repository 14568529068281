// Unpublished Work © 2024 Deere & Company

.action-center {
    margin: auto !important; // stylelint-disable-line declaration-no-important
}

.back-btn {
    margin-right: 10px !important; // stylelint-disable-line declaration-no-important
}

.card-actions {
    display: flex !important; // stylelint-disable-line declaration-no-important
    justify-content: end;
    padding: 8px 16px !important; // stylelint-disable-line declaration-no-important

    &.discover {
        padding: 0px 16px 16px !important; // stylelint-disable-line declaration-no-important
    }
}

.card-root {
    border-radius: 8px !important; // stylelint-disable-line declaration-no-important
}

.carousel-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

