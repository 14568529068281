// Unpublished Work © 2024 Deere & Company.

.feedback-form-container {
    display: flex;
    width: 100;

    .feedback-form-control {
        width: 100%;

        .feedback-form-label {
            font-weight: 600;
            margin-bottom: 4px !important; // stylelint-disable-line declaration-no-important
        }

        .feedback-text-form-control-label {
            margin-left: 4px;
        }

        .feedback-dialog-text-field {
            margin-top: 8px;

            textarea {
                resize: none;
            }
        }

        .other-feedback-text-count {
            text-align: right;
        }
    }
}
